<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
      <error-notification :show="existent">
        <span>{{ $t('notifications.goals.existent') }}</span>
      </error-notification>
    </div>
    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      @selected-district="adjustDistrict"
    />
    <div class="row">
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedType"
          :label="$t('goals.inputs.type')"
          :options="types"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          key-by="value"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          step="1"
          v-model="form.value.value"
          :disabled="loading"
          :label="$t('goals.inputs.valueInput')"
          :error="!!form.value.errors.length"
          :error-messages="translatedErrors(form.value.errors)"
          @blur="validate('value')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedPeriod"
          :label="$t('layout.form.periodInput')"
          :options="periodList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading || notValid"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')

export default {
  name: 'goals-form',
  components: {
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    goal: {
      type: Object,
      required: false,
      default: function () { return {} },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      selectedType: '',
      existent: false,
      selectedModel: '',
      associateds: [],
      selectedAssociated: '',
      locationFilters: ['regions', 'countries', 'districts'],
      initialDistrict: null,
      isLoading: {
        associated: false,
      },
      selectedPeriod: null,
      periodList: [],
      form: {
        value: {
          value: 0,
          validate: {
            required: true,
            minValue: 0,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    notValid () {
      return this.selectedType === '' || this.selectedModel === '' || this.selectedAssociated === ''
    },
    isNew () {
      return !Number.isInteger(this.goal.id)
    },
    types () {
      const t = [
        {
          name: this.$t('goals.types.predication'),
          value: 0,
        },
        {
          name: this.$t('goals.types.mti'),
          value: 1,
        },
        {
          name: this.$t('goals.types.training'),
          value: 2,
        },
        {
          name: this.$t('goals.types.instructors'),
          value: 3,
        },
        {
          name: this.$t('goals.types.planters'),
          value: 4,
        },
      ]

      return t
    },
    models () {
      const m = []

      /*
      if (this.currentUser.can('Regions', 'index')) {
        m.push({
          name: this.$t('goals.models.regions'),
          route: 'regions',
          value: 'regions'
        })
      }
      if (this.currentUser.can('Countries', 'index')) {
        m.push({
          name: this.$t('goals.models.countries'),
          route: 'countries',
          value: 'countries'
        })
      }
      */

      if (this.currentUser.can('Districts', 'index')) {
        const districts = {
          name: this.$t('goals.models.districts'),
          route: 'districts',
          value: 'districts',
        }
        // this.getAssociateds(this.selectedModel)
        m.push(districts)
      }

      return m
    },
  },
  watch: {
    goal (val) {
      this.setGoal(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.initYears()
    this.$nextTick(() => {
      this.validateAll()

      const districts = {
        name: this.$t('goals.models.districts'),
        route: 'districts',
        value: 'districts',
      }
      this.selectedModel = districts
    })
  },
  methods: {
    async setGoal (val) {
      this.setFormData(val)
      if (Number.isInteger(val.type)) {
        const type = this.types.find(t => t.value === val.type)
        if (type !== undefined) this.selectedType = type
      }
      if (val.year) {
        const year = Number(val.year)
        const period = `${year}-${year + 1}`
        this.selectedPeriod = { id: year, name: period }
      }

      if (val.model) {
        await this.$nextTick()
        const model = this.models.find(m => m.value === val.model)
        if (model !== undefined) this.selectedModel = model
        if (model && Number.isInteger(val.foreign_key)) {
          await this.getAssociateds(model)
          const associated = this.associateds.find(a => a.id === val.foreign_key)
          if (associated !== undefined) {
            this.selectedAssociated = associated
            this.initialDistrict = this.selectedAssociated
          }
        }
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.goal) {
        this.setGoal(this.goal)
      }
    },
    initYears () {
      const date = new Date()
      const firstYear = date.getFullYear() - 3
      // const month = date.getMonth() + 1
      const year = date.getFullYear()
      /* if (month < 6) {
        year -= 1
      } */
      const periods = []
      let currentPeriod = ''
      for (let i = firstYear; i <= year; i++) {
        const name = `${i}-${i + 1}`
        const period = {
          name: name,
          id: i,
        }
        if (i === year) currentPeriod = period
        periods.push(period)
      }

      this.periodList = periods.slice(0)
      this.selectedPeriod = currentPeriod
    },
    adjustDistrict (district) {
      this.selectedAssociated = district
    },
    async getAssociateds (model) {
      this.isLoading.associated = true
      this.selectedAssociated = null
      this.associateds = []

      let response = null
      try {
        response = await this.$http.get(model.route)
      } catch (e) {
        // console.log('Cant fetch model', e)
        this.isLoading.associated = false
        return
      }

      this.associateds = response.data.data
      this.isLoading.associated = false
    },
    async submit () {
      await this.validateAll()
      if (!this.formReady || this.notValid) return

      if (this.isNew) {
        const type = this.selectedType.value
        const model = this.selectedModel.value
        const associated = this.selectedAssociated.id
        const year = this.getFormValue('year')
        let existent = null
        const route = `goals?user=${this.currentUser.id}&type=${type}&model=${model}&associated=${associated}&year=${year}`
        try {
          existent = await this.$http.get(route)
        } catch (e) {
          console.error('Couldnt verify existance', e)
          return
        }

        if (existent.data.data.length > 0) {
          this.existent = true
          return
        }
      }

      const goal = this.getFormData(this.goal)
      goal.type = this.selectedType.value
      goal.model = this.selectedModel.value
      goal.foreign_key = this.selectedAssociated.id
      goal.alias = this.selectedAssociated.name
      if (this.selectedPeriod) {
        goal.year = this.selectedPeriod.id
      }
      if (goal.user) {
        delete goal.user
      }

      this.$emit('submit', goal)
    },
  },
}
</script>
